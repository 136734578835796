import * as React from 'react';

// Local Components
import CantFindProduct from '../../../components/CantFindProduct';

export default class ReturnsExchanges extends React.Component {
  render() {
    return (
      <div className="w-full px-1 ta-justify mb-6">
        <style jsx={true}>{`
          table {
            border: 1px solid #e0e0e5;
            border-spacing: 0;
            width: 100%;
            border-radius: 12px;
            text-align: left;
          }
          table thead tr td {
            padding: 15px 12px;
            color: #b1b2be;
            line-height: 18px;
            font-weight: bold;
            font-size: 12px;
          }
          table tr td {
            border-bottom-width: 1px;
            border-style: solid;
            border-color: #e0e0e5;
            padding: 17px;
            font-size: 12px;
            color: #1f2245;
            line-height: 18px;
          }
          .first-row {
            width: 26%;
          }
          .second-row {
            width: 33%;
          }
          .third-row {
            padding: 0;
          }
          ul {
            margin-left: -24px;
          }
          @media (min-width: 768px) {
            table tr td {
              font-size: 16px;
              line-height: 19px;
            }
            .first-row {
              width: 28%;
            }
            .second-row {
              width: 42%;
            }
            .third-row {
              padding: 0;
            }
          }
        `}</style>
        <h1 className="fs-xl color-navy fw-extra-bold my-1p5">Return &amp; Exchange Policy</h1>
        <h3 className="fs-lg color-navy fw-bold mb-p75">Returns</h3>
        <p className="mb-p75">
          Because our products are custom printed and unique to each order, they will only qualify for refund if the
          product itself is flawed, if the quality of the printing is poor, or if the final product is materially
          different from the product presented on the site.
        </p>
        <p className="mb-p75">
          Any unauthorized returns, or exchanges of items that are washed, worn, or damaged will not be eligible for a
          refund or replacement, and the item will be forfeited.
        </p>
        <p className="mb-1p5">Please note that customers are responsible for return shipping rates.</p>

        <h3 className="fs-lg color-navy fw-bold mb-p75">Refund</h3>
        <p className="mb-p75">
          Qualified refunds are processed immediately, but it may take 5-10 business days to appear on your statement
          depending on your method of payment.
        </p>
        <p className="mb-1p5">
          If we request that your item is returned to us, we’ll send the exchange or qualified refund your way after
          receiving your return. Please note that customers are responsible for any cost associated with the return.
        </p>
        <p className="mb-1p5">Refunds will not be offered for delays caused by shipper/carrier.</p>
        <h3 className="fs-lg color-navy fw-bold mb-p75">Claims</h3>
        <p className="mb-1p5">
          You must submit a claim within 30 days of the delivery date. Email us at{' '}
          <a
            href="mailto: claims@inkpop.com"
            className="color-blue underline fw-normal"
            style={{ textDecoration: 'underline' }}
          >
            claims@inkpop.com
          </a>{' '}
          with the following details. Order number, email, reason and photos of defect (if any)
        </p>

        <h3 className="fs-lg color-navy fw-bold mb-p75">Shipping</h3>
        <p className="mb-1p5">We offer 3 shipping options:</p>
        <table className="bc-navy bw-1">
          <thead>
            <tr>
              <td className="first-row">Option</td>
              <td className="second-row">Guaranteed Delivery Date</td>
              <td className="third-row" style={{ padding: 0 }}>
                Price
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="first-row">Standard</td>
              <td className="second-row">9 business days</td>
              <td className="third-row" style={{ padding: 0 }}>
                <p className="md:py-1">Free!</p>
              </td>
            </tr>
            <tr>
              <td className="first-row">Rush</td>
              <td className="second-row">4 business days*</td>
              <td className="third-row" style={{ padding: 0 }}>
                <p className="md:py-1">+15%</p>
              </td>
            </tr>
            <tr>
              <td className="first-row" style={{ borderBottomWidth: 0 }}>
                Super-rush
              </td>
              <td className="second-row" style={{ borderBottomWidth: 0 }}>
                3 business days*
              </td>
              <td className="third-row" style={{ borderBottomWidth: 0, padding: 0 }}>
                <p className="md:py-1">+25%</p>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="mt-p5 mb-p75 fs-sm">
          *Orders placed with expedited shipping are subject to an additional $5 shipping and handling fee.
        </p>
        <p className="mt-1 mb-p75">
          Delivery dates are guaranteed, meaning that if an order arrives past your delivery date, you can return it for
          a full refund.
        </p>
        <p className="mb-1p5">
          Note: The above chart applies to orders to the continental United States. Orders shipping outside the
          continental 48 states do not have guaranteed delivery dates. They may take an up to an additional 2 weeks to
          arrive.
        </p>

        <h3 className="fs-lg color-navy fw-bold mb-p75">Cancellation</h3>
        <p className="mb-p75">
          In order to meet our guaranteed delivery dates, cancellations can only be processed for a short period of time
          after your order is placed.
        </p>
        <ul className="mb-p5">
          <li>
            For orders where Standard(Free) Delivery is purchased, you may change or cancel your order within 2 hours
            once your order is placed.
          </li>
          <li>For orders where Super Rush or Rush Delivery is purchased, you may not change or cancel your order.</li>
        </ul>
        <p className="mb-p75">
          Any cancellation approved by customer service after the printing process has started is subject to a 15%
          restocking fee.
        </p>
        <p className="mb-1p5">
          To determine if your order qualifies for cancellation, please contact customer service by calling
          (877)-9-INKPOP.
        </p>

        <h3 className="fs-lg color-navy fw-bold mb-p75">Payment Policy</h3>
        <p className="mb-4">
          We accept Visa, Mastercard, American Express, and Discover credit card. At this time, we do not accept checks
          or purchase orders.
        </p>
        <CantFindProduct />
      </div>
    );
  }
}
