import * as React from 'react';

// Components
import SpecContainer from 'inkp-components/dist/Components/SpecContainer';

// Local Components
import CantFindProduct from '../../../components/CantFindProduct';

import { routes } from 'inkp-routes';
import config from 'inkp-config';

export default class FAQ extends React.Component {
  render() {
    return (
      <div className="w-full px-1 ta-justify mb-6">
        <style jsx={true}>{`
          table {
            border: 1px solid #e0e0e5;
            border-spacing: 0;
            width: 100%;
            border-radius: 12px;
            text-align: left;
          }
          table thead tr td {
            padding: 15px 12px;
            color: #b1b2be;
            line-height: 18px;
            font-weight: bold;
            font-size: 12px;
          }
          table tr td {
            border-bottom-width: 1px;
            border-style: solid;
            border-color: #e0e0e5;
            padding: 17px;
            font-size: 12px;
            color: #1f2245;
            line-height: 18px;
          }
          .first-row {
            width: 26%;
          }
          .second-row {
            width: 33%;
          }
          ul {
            margin-left: -24px;
          }
        `}</style>
        <h1 className="fs-xl color-navy fw-extra-bold my-1p5">Frequently Asked Questions</h1>
        <SpecContainer title="Artwork Specifications" classNames="my-1p5">
          <h3 className="fs-md color-navy fw-bold mb-p5">Accepted File Formats</h3>
          <ul className="mb-p5">
            <li>Raster Images: PNG,JPG/JPEG,GIF,BMP</li>
            <li>Vector Images: EPS,PS,AI</li>
            <li>Photoshop Images: TIF/TIFF</li>
          </ul>
          <p className="mb-p5">
            If you are having problems uploading a file, or if you need assistance converting a file to another format,
            send your artwork file to{' '}
            <a
              href="mailto: artist@inkpop.com"
              className="color-blue underline fw-normal"
              style={{ textDecoration: 'underline' }}
            >
              artist@inkpop.com
            </a>
            .
          </p>
          <h3 className="fs-md color-navy fw-bold mb-p5">Image Quality</h3>
          <p className="mb-p5">
            Vector files will produce the highest quality results. For non-vector files, upload an image with at least
            150 dots per inch.
          </p>
          <p className="mb-1p5">
            As a rule of thumb, the higher quality the image you upload, the higher quality your results will be. The
            image below shows the difference between a low quality image (left) and a high quality image (right).
          </p>
          <img
            alt="Image Quality"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_IMAGE+QUALITY.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Centering Your Design" classNames="my-1p5">
          <p className="mb-p5">
            If you are uncertain as to whether you've perfectly centered your design, don't worry. Simply attach a note
            to your order in the "notes" section of our design app to confirm that you would like to center your design.
          </p>
          <img
            alt="Center your design"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_CENTER+DESIGN.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Contrast Issues" classNames="my-1p5">
          <p className="mb-p5">
            After you submit your order, we'll review it to make sure that your design will be visible when printed on
            your garment. If we believe your design won't be visible when printed, we'll give you a call to let you know
            there's a problem.
          </p>
          <p className="mb-p5">
            Computer monitors tend to exaggerate the contrast between colors, making certain colors seem like they
            contrast well even when they do not. We advise against having a design that is the same color as the
            garment.
          </p>
          <p className="mb-p5">Examples of poor color contrast</p>
          <img
            alt="Center your design"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_BAD+CONTRAST.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Removing Background Colors" classNames="my-1p5">
          <p className="mb-p5">
            When you place your order, our production artists check to make sure your design does not have any excess
            background color.
          </p>
          <p className="mb-p5">
            In most cases, our artists can easily determine whether you intended to have the background color removed.
            (In the example below, we can easily assume the background color should be removed.) If we are unsure, one
            of our customer service representatives will give you a call to clarify the issue.
          </p>
          <img
            alt="Removing the background"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_REMOVE+THE+BACKGROUND.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Match Colors in Your Design" classNames="my-1p5">
          <p className="mb-p5">
            Our artists can help you match the colors of different elements in your design. In the image below, the
            design on the left contains text that is slightly different from the color of the image. We alter the design
            such that the colors match perfectly, shown on the design on the right.
          </p>
          <p className="mb-p5">
            To request that we match colors in different parts of your design, simply use the "Add Notes" feature in our
            design application to describe which colors you would like us to match.
          </p>
          <img
            alt="Color matching"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_Match+colors.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Print Dimensions" classNames="my-1p5">
          <p className="mb-p5">
            Our standard maximum print size is 14 inches wide by 16 inches tall. The maximum size will vary depending on
            the print location (eg. sleeves and pockets have smaller print sizes).
          </p>
          <p className="mb-p5">
            The images below show the common print dimensions on a basic adult large shirt. Please note that the actual
            size may vary depending on the type and size of the garment itself.
          </p>
          <img
            alt="Print dimensions"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_PRINT+DIMENSIONS.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Extra Screens" classNames="my-1p5">
          <h3 className="fs-md color-navy fw-bold mb-p5">Why do I need extra screens?</h3>
          <p className="mb-p5">
            Due to the nature of screen printing, the print will be the same size across all garments. As a result, for
            orders with a large range of sizes (eg. S to 4XL), the design will be disproportionately sized across the
            different shirts sizes. When you order extra screens, we will use 2 different print sizes, allowing the
            print to better scale to the size of the shirt.
          </p>
          <p className="mb-p5">
            Example: In the image below, the order is printed without extra screens. Because the design is printed the
            same size, it looks smaller on the adult shirt and larger on the youth shirt.
          </p>
          <img
            alt="Extra Screens"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_Extra+Screens.png"
            className="w-full mb-p5"
          />
        </SpecContainer>

        <SpecContainer title="Counting Print Colors" classNames="my-1p5">
          <p className="mb-p5">
            The number of colors in your design affects the price of your order. You can determine your color count by
            counting the number of colors in all text and images in your design, not including the color of the garment
            itself.
          </p>
          <p className="mb-p5">Some examples are shown below:</p>
          <img
            alt="Counting Colors"
            src="https://inkp-production.32pt.com/public/app/help-and-faq/INKPOP-HELP-ARTWORK_COUNTING+COLORS.png"
            className="w-full mb-p5"
          />
          <p className="mb-p5">
            If you need help counting colors in your design, give us a call at {config.contact.phone} and we'll be happy
            to help.
          </p>
        </SpecContainer>

        <SpecContainer title="Shipping to Multiple Addresses" classNames="my-1p5 ta-left">
          <p className="mb-p5">At this time, we do not offer split shipping.</p>
        </SpecContainer>

        <SpecContainer title="How Your Price is Determined" classNames="my-1p5 ta-left">
          <p className="mb-p5">The price of your order is determined based on three factors:</p>
          <ul className="mb-p5">
            <li>Style and color of garment</li>
            <li>Number of colors in the design</li>
            <li>Quantity and sizes</li>
          </ul>
          <h3 className="fs-md color-navy fw-bold mb-p5">Reducing Costs</h3>
          <p className="mb-p5">Doing any of the following will reduce your cost per shirt:</p>
          <ul className="mb-p5">
            <li>Reduce the number of colors or print locations in your design</li>
            <li>Choose a less expensive garment</li>
            <li>Order a white or natural colored garment</li>
            <li>Order more garments to reduce the per unit price</li>
          </ul>
        </SpecContainer>

        <SpecContainer title="How do I place an order?" classNames="my-1p5">
          <p className="mb-p5">To place an order, follow the steps below!</p>
          <ul className="">
            <li>
              Go to our{' '}
              <a
                href={routes.app.designTool}
                className="color-blue underline fw-normal"
                style={{ textDecoration: 'underline' }}
              >
                design application
              </a>{' '}
              to design your t-shirt.
            </li>
            <li>After finishing your design, click the blue "Checkout" button at the top right.</li>
            <li>Proceed through the checkout process and pay using a credit card.</li>
          </ul>
          <p className="mb-p5">
            Note: We begin the t-shirt printing process very quickly after orders are placed. Please make sure your
            order is finalized and 100% correct before placing the order.
          </p>
        </SpecContainer>

        <SpecContainer title="What types of payment do you accept?" classNames="my-1p5">
          <p className="mb-p5">
            We accept Visa, Mastercard, American Express, and Discover credit card. At this time, we do not accept
            checks or purchase orders.
          </p>
        </SpecContainer>

        <SpecContainer title="Shipping Options" classNames="my-1p5">
          <p className="mb-p5">We offer 3 shipping options:</p>
          <table className="mb-p5 bc-navy bw-1">
            <thead>
              <tr>
                <td className="first-row">Guaranteed Delivery Date</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="first-row">9 business days</td>
                <td>Free!</td>
              </tr>
              <tr>
                <td className="first-row">4 business days*</td>
                <td>+15%</td>
              </tr>
              <tr>
                <td className="first-row">3 business days*</td>
                <td>+25%</td>
              </tr>
            </tbody>
          </table>
          <p className="mb-p5 fs-sm">
            *Orders placed with expedited shipping are subject to an additional $5 shipping and handling fee.
          </p>
          <p className="mb-p5">
            Delivery dates are <span className="underline">guaranteed</span>, meaning that if an order arrives past your
            delivery date, you can return it for a full refund.
          </p>
          <p className="mb-p5">
            Note: The above chart applies to orders to the continental United States. At this point we do not offer
            International Shipping options.
          </p>
        </SpecContainer>

        <SpecContainer title="Misprints and Late Deliveries" classNames="my-1p5">
          <p className="mb-p5">
            If your order turned out to be materially flawed, or if arrived later than our guaranteed delivery date, you
            can receive a resolution by filing a claim.
          </p>
          <ul className="mb-p5">
            <li>To report a late or flawed product, please email claims@inkpop.com.</li>
            <li>Claims must be filed within 30 days after receipt of your goods.</li>
          </ul>
        </SpecContainer>

        <SpecContainer title="Can I cancel my order after placing it?" classNames="my-1p5">
          <p className="mb-p5">
            In order to meet our guaranteed delivery dates, we process orders very quickly after they are placed (often
            within minutes).
          </p>
          <ul className="mb-p5">
            <li>
              If you reach us over the phone before we begin processing your order, we can cancel the order for you.
            </li>
            <li>Once we start processing your order, we are unable to change or cancel your order.</li>
          </ul>
        </SpecContainer>
        <CantFindProduct />
      </div>
    );
  }
}
