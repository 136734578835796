import * as React from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import Helmet from 'react-helmet';
import * as Qs from 'qs';
import { IParseOptions } from 'qs';

// GTM helpers
import GTM from '../../util/gtm';
import { GTMTypes } from '../../interfaces/GTM';

// Components
import Tab from 'inkp-components/dist/Components/Tab';

// Local Components
import FAQ from './FAQ';
import ReturnsExchanges from './ReturnsExchanges';

interface State {
  tabs: {
    displayName: string;
    active: boolean;
  }[];
}

const parseOptions: IParseOptions = {
  ignoreQueryPrefix: true,
};
export default class HelpFaq extends React.Component<RouteComponentProps> {
  state: State = {
    tabs: [
      {
        displayName: 'Help & FAQ',
        active: true,
      },
      {
        displayName: 'Returns & Exchanges',
        active: false,
      },
    ],
  };

  componentDidMount() {
    const {
      location: { search },
    } = this.props;
    const { tab } = Qs.parse(search, parseOptions);
    this.updateTab(tab);

    setTimeout(() => {
      GTM.push(GTMTypes.USER);
    }, 0);
  }

  updateTab = (field: string) => {
    const { tabs } = this.state;
    const newTabs = tabs.map((tab: any) => {
      let newTab = {};
      if (tab.displayName === field) {
        newTab = Object.assign({}, { ...tab }, { active: true });
      } else {
        newTab = Object.assign({}, { ...tab }, { active: false });
      }
      return newTab;
    });
    this.setState({ tabs: newTabs });
    this.props.history.push({
      search: Qs.stringify({ tab: field }),
    });
  };

  renderContent = () => {
    const {
      location: { search },
    } = this.props;
    let content = null;
    const { tab } = Qs.parse(search, parseOptions);
    switch (tab) {
      case 'Help & FAQ':
        content = <FAQ />;
        break;
      case 'Returns & Exchanges':
        content = <ReturnsExchanges />;
        break;
      default:
        content = <FAQ />;
        break;
    }
    return content;
  };

  render() {
    const { tabs } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Inkpop - Help &amp; FAQ</title>
        </Helmet>
        <React.Fragment>
          <div className="w-full bgc-gray-50">
            <div className="w-full flex flex-wrap md:w-container md:mx-auto md:py-0">
              <div className="d-ib w-1/2 ta-left py-1p5 px-1 flex items-center fs-xs">
                <Link to={'/'} className={''}>
                  <span className="d-ib color-blue">Home</span>
                </Link>
                <div className="d-ib">
                  <i className={'mdi mdi-chevron-right p-0 color-gray-500 fw-bold'} />
                </div>
                <div className="d-ib">
                  <span className="d-ib color-blue color-gray-500 fw-bold">Help</span>
                </div>
              </div>
              <div className="w-full ta-left pb-p75 px-1">
                <h3 className="color-navy fs-2xl fw-extra-bold">How can we help you?</h3>
              </div>
              <div className="w-5/6 pl-1 md:w-1/3 md:ta-left md:pr-0">
                <Tab tabs={tabs} onClick={this.updateTab} />
              </div>
            </div>
          </div>
          <div className="w-full bgc-white">
            <div className="w-full md:w-container md:mx-auto md:py-0">
              <div className="w-full md:w-7/10 md:mx-auto">{this.renderContent()}</div>
            </div>
          </div>
        </React.Fragment>
      </React.Fragment>
    );
  }
}
